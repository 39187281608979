<template>
  <div>
    <div id="appForm" class="mx-auto col-11 mt-5">
      <div class="flex flexLeft mx-auto contentsWidth">
        <p class="h4 text-center fw-3">{{year}}年度 {{name}} - 申請</p>
      </div>
      <hr class="title contentsWidth">

      <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
        <p class="mb-1">・{{allPageNo}}シートすべての必要項目に入力後、登録を行ってください。</p>
        <p class="mb-1">・数字は半角、カナは全角で入力してください。</p>
      </div>

      <b-tabs
        class="gas-tab mb-3 mx-auto contentsWidth"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          v-if="viewControl">
          <template v-slot:title>
            <span>申請情報</span>
          </template>
          <v-app-controlview
            :code="code"
            :status="status"
            :reason="reason"/>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>申請者情報</span>
            <img
              v-if="hasUserErr"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
          <v-app-userinfo
            :writable="canSend"
            :close_datetime="close_datetime"/>
        </b-tab>
        <b-tab
          @click="scrollBar(index)"
          v-for="(page, index) in pages" :key="index">
          <template v-slot:title>
            <span>{{page.name}}</span>
            <img
              v-if="hasPageErr[index]"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
          <v-app-form
            :writable="canSend"
            :pageIndex="index"
            :assets="assets"
            :downloadFormFile="page.name === '追加資料' ? downloadFormAttFile: downloadFormFile"
            :downloadOpenFile="downloadOpenFile"/>
        </b-tab>
      </b-tabs>

      <div class="bottonArea mt-4 mb-3">
        <p
          v-show="hasEmpty"
          class="warning flex flexCenter">
          未入力の必須項目があります。すべての必須項目に入力後、{{textIsEdit}}を行ってください。
        </p>
        <p
          v-show="canExport"
          class="flex flexCenter">
          PDF出力では、保存した内容が出力されます。入力中の内容は出力されませんので、ご注意ください。
        </p>
      </div>

      <div class="mt-4 mb-3 flex flexCenter contentsWidth">
        <b-button class="btn btn-lg bold" to="/top">トップに戻る</b-button>
        <!-- 申請期間内かつ未提出・一時保存の場合のみ表示 -->
        <b-button
          v-if="onTime && isUnsubmited"
          @click="tempSave"
          variant="primary"
          class="btn btn-lg ml-3 bold"
          >一時保存</b-button>
        <b-button
          v-if="canSend"
          @click="save"
          variant="primary"
          class="bold ml-3"
          >{{textIsEdit}}</b-button>
        <b-button
          v-if="(onTime && isSubmited) || isTempSave"
          @click="withdraw"
          variant="dark"
          class="btn btn-lg ml-3 bold"
          >申請の削除</b-button>
        <b-button
          v-if="canExport"
          @click="exportPdf"
          variant="outline-primary"
          class="btn btn-lg ml-3 bold wide-btn"
          >PDF出力</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import Userinfo from '@/components/parts/application/Userinfo.vue';
import AppForm from '@/components/gas/parts/Form.vue';
import AppControlview from '@/components/gas/parts/Controlview.vue';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'gasApplicationForm',
  components: {
    'v-app-userinfo': Userinfo,
    'v-app-form': AppForm,
    'v-app-controlview': AppControlview,
  },
  data() {
    return {
      isEdit: false,
      apptypeId: null,
      year: null,
      name: '',
      code: null,
      reason: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      initTabChange: {},
      onTime: null,
      viewLocateMsg: true,
      formStatus: null,
      assets: {},
      close_datetime: null,
      pageErrs: [],
      exportStatusList: [],
      attachment_id: null,
    };
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    hasEmpty() {
      // ユーザー情報
      const userInfo = this.$store.state.userInfo.inputData;
      const userInfoForm = this.$store.state.userInfoForm.form;
      const findUserResult = Object.keys(userInfo).find((key) => {
        if (!userInfoForm[key].is_required) {
          return false;
        }
        return userInfo[key] === '' || userInfo[key] === null;
      });

      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      return typeof findUserResult !== 'undefined' || typeof findFormResult !== 'undefined';
    },
    isUnsubmited() {
      return this.status === null || this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    isTempSave() {
      return this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    isSubmited() {
      return this.status === CONST_STATUS.APP_STATUS.SUBMIT;
    },
    viewControl() {
      const viewControlStatusList = CONST_STATUS.GAS_VIEW_CONTROL_STATUS;
      return viewControlStatusList.indexOf(this.status) !== -1;
    },
    formStatuses() {
      return CONST_STATUS.FROM_STATUS;
    },
    canSend() {
      const isInit = this.formStatus === this.formStatuses.INIT;
      const isEdit = this.formStatus === this.formStatuses.EDIT;
      return isInit || isEdit;
    },
    hasChange() {
      return this.$store.state.gasApplicationForm.hasChange;
    },
    canExport() {
      return this.exportStatusList.includes(this.status);
    },
    allPageNo() {
      return this.pages.length + 1; // 動的なページとユーザー情報の和
    },
    textIsEdit() {
      if (!this.isEdit) {
        return '申請';
      }
      return '再申請';
    },
  },
  methods: {
    async fetchApptypeData(apptypeId) {
      const param = {
        apptypeId,
      };
      const promiseFuncs = [
        api.send('/api/application/base', param),
        api.send('/api/application/myapp', param),
        api.send('/api/application/form', param),
        api.send('/api/application/edit', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.error(err);
        });
      if (!responses) {
        return;
      }
      const { base, onTime, exportStatusList } = responses[0].data;
      this.year = base.year;
      this.name = base.name;
      this.onTime = onTime;
      this.assets = base.assets;
      this.close_datetime = base.close_datetime;
      this.exportStatusList = exportStatusList;
      const { myapp, formStatus } = responses[1].data;
      this.formStatus = formStatus;
      if (myapp !== null) {
        this.code = myapp.code;
        this.reason = myapp.reason;
        this.status = myapp.status;
        // 一時保存の時はデータがあっても編集ではない
        this.isEdit = this.status !== CONST_STATUS.APP_STATUS.TEMPSAVE;
      }
      const { pages } = responses[2].data;
      const { userInfo, answers, applicationId, attachmentId } = responses[3].data;
      this.attachment_id = attachmentId;
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setApplicationId', applicationId);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.$store.commit('userInfo/setDbData', userInfo);
      this.$store.commit('gasApplicationForm/setApplicationTypeName', base.name);
    },
    async tempSave() {
      this.pageErrs = [];
      if (!await this.confirm('現在の内容で一時保存をしてもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        apptypeId: this.apptypeId,
        userInfo: JSON.stringify(this.$store.state.userInfo.inputData),
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/application/tempSave', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });

      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      this.$store.commit('userInfo/resetErrorMsg');
      this.$store.commit('gasApplicationInput/initErrMessages');
      this.$store.commit('gasApplicationForm/setHasChange', false);
      this.hasUserErr = false;
      this.hasPageErr = [];
      await this.fetchApptypeData(this.apptypeId);
      this.$store.dispatch('page/offWaiting');
      await this.alert('一時保存しました。');
    },
    async save() {
      if (!await this.confirm('現在の内容で申請をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        apptypeId: this.apptypeId,
        userInfo: JSON.stringify(this.$store.state.userInfo.inputData),
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/application/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/setCode', response.data.code);
      // リダイレクト
      this.$router.push({ name: 'gasApplicationEnd', meta: { locate: false } });
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        apptypeId: this.apptypeId,
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/application/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$store.state.gasApplicationForm.application_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/application/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadFormAttFile(fieldId, optionId, fileName) {
      const param = {
        attachment_id: this.attachment_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/attachments/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    setErrorMarks(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    async withdraw() {
      if (!await this.confirm('入力内容はすべて破棄されます。この申請を削除してもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptypeId: this.apptypeId,
      };
      const response = await api.send('/api/application/withdraw', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('申請を削除しました。');
      // トップへリダイレクト
      this.viewLocateMsg = false;
      this.$router.push({ name: 'gasTop' });
    },
    // ページ上部にスクロールし、スクロールバーを表示する。settimeoutがないと効かない。scrollUserinfoもなぜか効かない
    // createに書いてもだめ
    scrollBar(index) {
      if (!this.initTabChange[index]) {
        setTimeout(() => {
          document.querySelector('#scrollUserinfo').scrollTop = 1;
          Object.keys(this.pages).forEach((page) => {
            document.querySelector(`#scroll${page}`).scrollTop = 1;
          });
        }, 1);
        this.$set(this.initTabChange, index, true);
      }
    },
    async exportPdf() {
      this.$store.dispatch('page/onWaiting');
      const params = {
        apptypeId: this.apptypeId,
      };
      const requireBlob = true;
      const response = await api.send('/api/application/export/mypdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。お手数ですが、添付いただいたファイルのPDF変換をもう一度やり直すか、別の方法でPDFへの変換をお試しください。それでも解消しない場合は、事務局までお問い合わせください。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.name}.pdf`;
      download.blob(response.data, fileName);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.hasChange || to.name === 'gasApplicationEnd' || this.viewLocateMsg === false) {
      next();
    } else if (await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
      next();
    } else {
      next(false);
    }
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('userInfo/resetErrorMsg');
    this.$store.commit('gasApplicationForm/setIsCms', false);
    const { apptypeId } = this.$route.params;
    this.apptypeId = apptypeId;
    this.$store.commit('gasApplicationForm/setApplicationTypeId', apptypeId);
    await this.fetchApptypeData(apptypeId);
    this.$store.dispatch('page/offLoading');

    setTimeout(() => {
      document.querySelector('#scrollUserinfo').scrollTop = 1;
    }, 0);
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
    height: 80px;
  }

  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  #print {
    display: none;
  }
  #appForm {
    display: block;
  }
  @media print{
    #print{
      overflow-y: visible;
      display: block;
      white-space:pre-wrap;
      word-wrap: break-word;
    }
    #appForm{
      display: none;
    }
  }
</style>
